import styled from "styled-components";
import { up } from "styled-breakpoints";
import Link from "../Link";

interface ButtonProps {
  color: string;
}

const Button = styled(Link)<ButtonProps>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  color: ${({ theme, color }) =>
    color === "navy" ? theme.colors.offWhite : theme.colors[color]};
  padding: 0.9rem 1.5rem;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.helvetica};
  text-align: center;
  outline: 1px solid ${({ theme, color }) => theme.colors[color]};
  transition: all 0.1s ease-in-out;

  ${up("tablet-portrait")} {
    width: fit-content;
  }

  &:visited {
    color: ${({ theme, color }) =>
      color === "navy" ? theme.colors.offWhite : theme.colors[color]};
  }

  &:hover,
  focus {
    background-color: ${({ theme, color }) =>
      color === "navy" ? theme.colors.offWhite : theme.colors[color]};
    color: ${({ theme, color }) =>
      color === "navy" ? theme.colors[color] : theme.colors.offWhite};
  }
`;

export default Button;
